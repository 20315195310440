/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, Fragment} from "react";


import { AppContext } from "../components/AppProvider";
import { _CK, _API, _U } from "../modules/utils";

// UI
import { Loading } from "../components/UI/Loading";
import { _CFG } from "../../../api/src/modules/config";
import useStore from "../modules/utils/store";

export default (props) => {
  const { auth, iam } = useContext(AppContext);
  const { saveAuth, saveIam } = useContext(AppContext);
  const { eventIdx, sessionIdx, passCode, au, type } = props;
  const { medigate, external } = props;
  const {name, mobile, email, smsAgree, emailAgree, remoteAddr, companyName} = props;
  const { siteInfo } = useStore();

  const directLogin = () => {
    if(medigate || external){
      _CK.clearAuth();
      _CK.setLogKey(null);
      _CK.setConsoleCountView(0);
      const req = {
          path: "/v1/client/medigate/access",
          data: {
              siteIdx: siteInfo.idx,
              eventIdx,
              sessionIdx,
              name,
              mobile,
              email,
              smsAgree,
              emailAgree,
              companyName,
              remoteAddr,
              targetType: medigate ? 'MEDIGATE' : external ? 'ICOD' : '',
              userAgent: _U.getUserAgent(),
          }
      };
      _API
      .post(req)
      .then((res) => {
          const { data } = res;
          if (res.status !== "SUCCESS" || !data || !data.accessKey) {
              alert("접근 권한이 없습니다.");
              window.location.href = `/`;
          } else {
              _CK.setAuth(data.accessKey);
              _CK.setLogKey(data.data.logKey);
              _CK.setConsoleCountView(0);
              saveIam(data.data);
              saveAuth(data.accessKey);
          }
      })
      .catch((e) => {
          window.location.href = `/`;
      });
    }
    else if (eventIdx && passCode) {
      _CK.clearAuth();
      _CK.setLogKey(null);
      _CK.setConsoleCountView(0);
      const req = {
        path: "/v1/client/login",
        data: {
          passCode,
          siteIdx: siteInfo.idx,
          eventIdx,
          userAgent: _U.getUserAgent(),
          type: type
        },
      };
      _API
        .post(req)
        .then((res) => {
          const { data } = res;
          if (res.status !== "SUCCESS") {
            alert("접근 권한이 없습니다.");
            window.location.href = `/`;
          } else {
            _CK.setAuth(data.accessKey);
            _CK.setLogKey(data.data.logKey);
            _CK.setConsoleCountView(0);
            saveIam(data.data);
            saveAuth(data.accessKey);
          }
        })
        .catch((e) => {
          window.location.href = `/`;
        });
    } else if (eventIdx && au) {
      _CK.clearAuth();
      _CK.setLogKey(null);
      _CK.setConsoleCountView(0);
      const req = {
        path: "/v1/client/adminLogin",
        data: {
          siteIdx: siteInfo.idx,
          eventIdx,
          userAgent: _U.getUserAgent(),
          email:props?.email,
          memType:props?.memType
        },
        headers: {
          authorization: au,
          apiKey: _CFG.apiKey,
        },
      };
      _API
        .post(req)
        .then((res) => {
          const { data } = res;
          if (res.status !== "SUCCESS" || !data || !data.accessKey) {
            alert("접근 권한이 없습니다.");
            window.location.href = `/`;
          } else {
            _CK.setAuth(data.accessKey);
            _CK.setLogKey(data.data.logKey);
            _CK.setConsoleCountView(0);
            saveIam(data.data);
            saveAuth(data.accessKey);
          }
        })
        .catch((e) => {
          window.location.href = `/`;
        });
    }
  };

  useEffect(() => {
    directLogin();
  }, []);

  return (
    <Fragment>
      <img
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "200px",
          height: "200px",
          display: "block",
          transform: "translate(-50%,-50%)",
        }}
        className="loading_img"
        src="https://cdn.onoffevent.com/common/loading.gif"
      />
    </Fragment>
  );
};
