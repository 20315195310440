import React, { useState, Fragment, useEffect, useContext, useRef } from "react";
import { AppContext } from "../../../components/AppProvider";
import { _API, _CK, _U } from "../../../modules/utils";
import { Modal, Button } from "react-bootstrap";
import { styled } from '@mui/material/styles';
import checkImg from "../../../resources/console/images/icon/6555.svg"
import errorMsgImg from "../../../resources/console/images/icon/errorMsgImg.svg"
import { TextareaAutosize,FormControl,RadioGroup,FormControlLabel,Radio,FormGroup,Checkbox } from "@mui/material";
import useStore from "../../../modules/utils/store";

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: "50%",
  width: 8,
  height: 8,
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  border: "8px solid #dddddd",
  boxSizing:"content-box",
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
  '& .MuiRadio-root:hover': {
    backgroundColor: "unset"
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  border: "8px solid #7758fa",
  backgroundColor: '#fff',
  boxSizing:"content-box",
  'input:hover ~ &': {
    backgroundColor: '#eee',
  },
});
// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      sx={{ padding:"0",marginRight:"4px","&:hover": {backgroundColor:"unset"} }}
      {...props}
    />
  );
}
// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      sx={{padding:"0",marginRight:"4px","&:hover": {backgroundColor:"unset"}}}
      {...props}
    />
  );
}
function SurveyModal(props) {
  const { iam } = useContext(AppContext);
  const { consoleCfg } = props;
  const { surveyCallback, show, setShow, surveyInfo ,setSurveyInfo} = props;
  const [surveys, setSurveys] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageTotalCount, setPageTotalCount] = useState(0);
  const [submit, setSubmit] = useState(false);
  const inputRef = useRef([]);
  const { playerSetting } = useStore();

  useEffect(() => {
    setSurveys([]);
    setPageTotalCount(0)
    setSubmit(false);
    if(surveyInfo.idx > 0 && iam?.preregIdx > 0){
      const req = {
        path: "/v1/event/session/surveys/mySurvey/cnt",
        data: {
          surveyIdx : surveyInfo.idx,
          preregIdx : iam?.preregIdx
        },
      };
      _API.post(req).then((res) => {
        if(playerSetting!= null && playerSetting.getFullscreen() == true){
          playerSetting.setFullscreen(false);
        }
        let result = res.data;
        if(result.data.cnt == 0){
          if(surveyInfo.show){
            setPageNum(1)
            settingSurvey(surveyInfo.idx)
            setShow(true);
          }
        }else{
          if(surveyInfo.clickStatus == "click"){
            alert("This is a survey you have already participated in.")
          }
        }
      });
    }
  },[surveyInfo]);

  const openSurvey = async (status,clickStatus,surIdx) => {
    let surveyIdx = 0;
    if(surIdx > 0){
      surveyIdx = surIdx;
    }
    const req = {
      path: "/v1/event/session/surveys/find/menu/connect/survey",
      data : {
        eventIdx: consoleCfg?.eventIdx,
        sessionIdx: consoleCfg?.sessionIdx,
        status : status,
        surveyIdx : surveyIdx,
      }
    };
    await _API.post(req).then((res) => {
      if(res.data.data.idx > 0){
        const surveyData = {
          show : true,
          idx : res.data.data.idx
        }
        setSurveyInfo(surveyData)
        if(clickStatus == "click"){
          setSurveyInfo((o) => {
            let si = {...o};
            si.clickStatus = `click`;
            return si;
          })
        }
      }else{
        if(status == 1){
          alert('There are currently no surveys in progress.');
        }
        return;
      }
    });
  }

  const settingSurvey = (surveyIdx) => {
    setSurveys([]);
    setPageTotalCount(0)
    setSubmit(false);
    const req = {
      path: "/v1/event/session/surveys/view",
      data: {
        idx : surveyIdx,
        preregIdx : iam?.preregIdx
      },
    };
    _API.post(req).then((res) => {
      let result = res.data;
      if(result?.cnt.cnt > 0){
        setSubmit(true);
      }
      
      setSurveys(result?.data);
      setPageTotalCount(result?.data.pages.length)
    });
  };

  const handleClose = () => {
    setShow(false);
    surveyCallback && surveyCallback();
  };
  
  const saveSurvey = async (e) => {
    let cnt = 0;
    let nextStep = 0;
    surveys.pages.map((page , i) => {
      if(pageNum == page.order){
        page.questions.map((question , j) => {
          let cnt2 = 0;
          if(question.required == "Y"){
            question.opts.map((answer , k) => {
              if(answer.userValue != false && answer.userValue != null && answer.userValue != ""){
                cnt2++;
              }
            })
            if(cnt2 == 0){
              cnt++;
              setSurveys((o) => {
                let survey = {...o};
                survey.pages[i].questions[j].errorMsg = `This is a required response question.`;
                return survey;
              })
            }
          }
        })
      }
    })
    if(cnt == 0){
      surveys.pages.map((page , i) => {
        if(pageNum == page.order){
          page.questions.map((question , j) => {
            setSurveys((o) => {
              let survey = {...o};
              delete survey.pages[i].questions[j]["errorMsg"];
              return survey;
            })
          })
        }
      })
      nextStep++;
    }else{
      alert("Please answer the required questions.")
      return
    }
    
    

    if(nextStep > 0){
      let eventIdx = consoleCfg?.eventIdx;
      let sessionIdx = consoleCfg?.sessionIdx;
      let preregIdx = iam?.preregIdx;
      let surveyIdx = surveys?.idx;
      let array = [];
      let pageOrder = 0;
      surveys?.pages.map((page, i) => {
        page?.questions.map((question, j) => {
          let values = {"preregIdx":preregIdx, "surveyIdx":surveyIdx, "eventIdx":eventIdx, "sessionIdx":sessionIdx, "questionIdx":question.idx};
          if(question.required != "Y" && question?.questionType == 'MULTIPLE' && question?.opts?.filter((o) => o?.userValue != null)?.length <= 0){
            array.push({...values,["optionIdx"] : 0, ["optionLabel"] : null, ["optionValue"] : null});
          }else if(question.required != "Y" && question?.questionType == 'SUBJECT' && question?.opts?.filter((o) => o?.userValue != null)?.length <= 0){
            array.push({...values,["optionIdx"] : 0, ["optionLabel"] : null, ["optionValue"] : null});
          }else{
            question?.opts.map((option, k) => {
              if(question.required == "Y"){
                if(!option.userValue == false && option.userValue != null && option.userValue != ""){
                  array.push(
                    {...values,
                      ["optionIdx"]   : question.questionType == "SUBJECT" ? 0 : option.idx, 
                      ["optionLabel"] : question.questionType == "SUBJECT" ? null : option.label, 
                      ["optionValue"] : option.userValue
                    }
                  );
                }else{
                  if(pageOrder == 0){
                    pageOrder = i+1;
                  }
                }
              }else{
                if(!option.userValue == false && option.userValue != null && option.userValue != ""){
                  array.push(
                    {...values,
                      ["optionIdx"]   : question.questionType == "SUBJECT" ? 0 : option.idx, 
                      ["optionLabel"] : question.questionType == "SUBJECT" ? null : option.label, 
                      ["optionValue"] : option.userValue
                    }
                  );
                }
              }
            })
          }
        })
      })
      
      if(window.confirm(`Would you like to submit a survey?\nYou cannot edit your answer after submission.`)){
        const req = {
          path: "/v1/event/session/surveys/save",
          data: {
            answers : array
          },
        };
        _API.post(req).then((res) => {
          let result = res.data.result;
          if (result > 0) {
            setSubmit(true)
          }
        });
      } 
    }
  };
  
  const nextSurvey = (e,pageNum) => {
    let cnt = 0;
    surveys.pages.map((page , i) => {
      if(pageNum == page.order){
        page.questions.map((question , j) => {
          let cnt2 = 0;
          if(question.required == "Y"){
            question.opts.map((answer , k) => {
              if(answer.userValue != false && answer.userValue != null && answer.userValue != ""){
                cnt2++;
              }
            })
            if(cnt2 == 0){
              cnt++;
              setSurveys((o) => {
                let survey = {...o};
                survey.pages[i].questions[j].errorMsg = `This is required.`;
                return survey;
              })
            }else{
              setSurveys((o) => {
                let survey = {...o};
                delete survey.pages[i].questions[j]["errorMsg"];
                return survey;
              })
            }
          }
        })
      }
    })
    if(cnt == 0){
      surveys.pages.map((page , i) => {
        if(pageNum == page.order){
          page.questions.map((question , j) => {
            setSurveys((o) => {
              let survey = {...o};
              delete survey.pages[i].questions[j]["errorMsg"];
              return survey;
            })
          })
        }
      })
      let num = pageNum + 1;
      if(pageNum < pageTotalCount){
        setPageNum(num);
      }
    }else{
      alert("Please answer the required questions.")
      return
    }
  };
  
  const prevSurvey = (e) => {
    let num = pageNum - 1;
    if(pageNum > 0){
      setPageNum(num);
    }
  };
  useEffect(() => {
    let survey_sub = $(".survey_container .modal_header p");
    let urvey_subH = survey_sub.outerHeight();
    if (urvey_subH > 120) {
      survey_sub.css("font-size", "14px")
    }
  });

  const onReceivedMessage = (e) => {
    _U.parseMessage(e).then((data) => {
      switch (data.code) {
          case "SOCKET-RECEIVED":
              return data.data ? JSON.parse(data.data) : null;
      }
    }).then((data) => {
      if(data?.mode == 'SURVEY_DATA_MENU_CONN'){
      
      }
      if (data?.mode === "SURVEY_OPEN") {
        openSurvey(3,"click",data.idx)
      } 
      if (data?.mode === "SURVEY_CLOSE") {
        setShow(false);
      }
      if(data?.mode == 'CONN_DISCONN'){
      
      }
    });
  };

  useEffect(() => {
    window.addEventListener("message", onReceivedMessage);
    return () => {
      window.removeEventListener("message", onReceivedMessage);
    }; 
  }, []);

  useEffect(() => {
    const req = {
      path: "/v1/event/session/surveys/find/surveyIdx",
      data: {
        eventIdx : consoleCfg.eventIdx,
        sessionIdx : consoleCfg.sessionIdx,
        preregIdx : iam?.idx,
      },
    };
    _API.post(req).then((res) => {
      let result = res.data;
      if (result?.data.idx > 0) {
        if(result?.cnt.cnt > 0){
          setSubmit(true);
          setPageNum(1)
          settingSurvey(result?.data.idx);
          setShow(true);
        }
      }
    });
  },[]);

  const onSetOpt = (pageIndex, questionIndex, optIndex, value, checked) => {
    setSurveys((o) => {
      let survey = {...o};
      let target = survey?.pages[pageIndex]?.questions[questionIndex];
      if(checked == true){
        if(target?.questionType == 'MULTIPLE' && target?.answerType == 'SINGLE'){
          survey?.pages[pageIndex]?.questions[questionIndex].opts?.map((opt, k) => {
            if(k == optIndex) {
              opt.userValue = value;
            }else{
              delete opt["userValue"];
            }
          })
        }else{
          survey.pages[pageIndex].questions[questionIndex].opts[optIndex].userValue = value;
        }
      }else{
        delete survey.pages[pageIndex].questions[questionIndex].opts[optIndex]["userValue"];
      }
      
      return survey;
    })
  }
  
  return (
    <Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="survey_container"
      >
        <div className="modal_header">
          <div className="modal_header_inner">
            <div className="modal_title" id="survey_title">
              <div dangerouslySetInnerHTML={{ __html: surveys?.title }}></div>
            </div>
          </div>
          {surveys?.closeBtnUse == 'Y' && (
            <div className="close" onClick={handleClose}></div>
          )}
        </div>
        <div className="m_width_wrap">
          <div className="modal_body">
            {surveys?.topDescriptionShow == 'Y' && (
              <div id="survey_description" className="modal_top_desc">
                <div dangerouslySetInnerHTML={ {__html: surveys?.topDescription} }></div>
              </div>
            )}
            {submit ? (
              <div className="submit_comfirm">
                <img src={checkImg} alt="제출완료" />
                <p>Your submission has been completed. Thank you for your participation.</p>
              </div>
            ) : (
              surveys?.pages && surveys?.pages.map((page, i) => {
                return (
                  page.order == pageNum && (
                    <div key={i}>
                      {(page.titleShow == "Y" || page.descriptionShow == 'Y') && (
                        <div className="modal_page_desc">
                          {page.titleShow == 'Y' && (
                            <h5 dangerouslySetInnerHTML={{ __html: page.title }}></h5>
                          )}
                          {page.descriptionShow == 'Y' && (
                            <div style={{ marginTop: "10px" }}>
                              <div dangerouslySetInnerHTML={{ __html: page.description }}></div>
                            </div>
                          )}
                        </div>
                      )}
                      {page.questions && page.questions.map((question, j) => {
                        return (
                          <div key={j} className="form-group">
                            <div className="survey_question">
                              {question.question}{question.required == "Y" && (<span style={{ color: "red" }}>*</span>)}
                            </div>
                            <div className="survey_choice">
                              {question.questionType == "SUBJECT" ? (
                                <TextareaAutosize
                                  aria-label="empty textarea"
                                  ref={(el => (inputRef.current[i] = el))}
                                  value={question.opts[0]?.userValue || ''}
                                  style={{ width: "100%", height: 100, borderLeft: "1px solid #111" }}
                                  maxRows={4}
                                  onChange={(e) => onSetOpt(i, j, 0, e.target.value, true)}
                                />
                              ) : (
                                <>
                                  {question.answerType == 'SINGLE' && (
                                    <FormControl sx={{ marginLeft: "0", "& .MuiTypography-root": { wordBreak: "break-all" } }}>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="radio-buttons-group"
                                      >
                                        {question.opts && question.opts.map((option, k) => {
                                          return (
                                            <FormControlLabel
                                              key={k}
                                              value={option.value}
                                              sx={{ marginLeft: "0", marginBottom: "20px", alignItems: "flex-start" }}
                                              control={<BpRadio onChange={(e) => onSetOpt(i, j, k, e.target.value, e.target.checked)} checked={option.value == option.userValue} />}
                                              label={option.label}
                                            />
                                          )
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  )}
                                
                                  {question.answerType == 'MULTI' && (
                                    <FormGroup>
                                      {question.opts && question.opts.map((option, k) => {
                                        return (
                                          <FormControlLabel
                                            key={k}
                                            sx={{ marginLeft: "0", marginBottom: "20px", alignItems: "flex-start" }}
                                            control={<BpCheckbox onChange={(e) => onSetOpt(i, j, k, e.target.value, e.target.checked)} checked={option.value == option.userValue} />}
                                            value={option.value}
                                            label={option.label}
                                          />
                                        )
                                      }
                                      )}
                                    </FormGroup>
                                  )}
                                </>
                              )}
                            </div>
                            {question.errorMsg != false && question.errorMsg != null && question.errorMsg != "" &&
                              <div className="errorMsg"><img src={errorMsgImg} /><span>{question.errorMsg}</span></div>
                            }
                          </div>
                        )
                      })}
                      {pageTotalCount == pageNum &&
                        <div className="modal_btm_desc" style={{ padding: '20px 30px' }}>
                          <div dangerouslySetInnerHTML={{ __html: surveys?.bottomDescription }}></div>
                        </div>
                      }
                    </div>
                  )
                )
              })
            )}
            <div className="modal_footer m_width_modal_footer">
              {!submit && pageNum > 1 && (<Button onClick={(e) => prevSurvey(e)}>Prev</Button>)}
              {submit ? (<Button style={{ marginLeft: "auto" }} onClick={handleClose}>Confirm</Button>)
              : pageTotalCount > pageNum ?
                (<Button style={{ marginLeft: "auto" }} onClick={(e) => nextSurvey(e, pageNum)}>Next</Button>)
              : (<Button style={{ marginLeft: "auto" }} onClick={(e) => saveSurvey(e, pageNum)}>Submit</Button>)
              }
            </div>
          </div>
        </div>
        
        <div className="modal_body">
          {surveys?.topDescriptionShow == 'Y' && (
            <div id="survey_description" className="modal_top_desc modal_top_desc_pc_mobile">
              <div dangerouslySetInnerHTML={ {__html: surveys?.topDescription} }></div>
            </div>
          )}
          {submit ? (
            <>
              <div className="submit_comfirm">
                <img src={checkImg} alt="제출완료"/>
                <p>Your submission has been completed. Thank you for your participation.</p>
              </div>
            </>
          ) : (
            <>
              {surveys?.pages && surveys?.pages.map((page, i) => {
                return (
                  page.order == pageNum && (
                    <div key={i}>
                      {(page.titleShow == "Y" || page.descriptionShow == 'Y') && (
                        <div className="modal_page_desc">
                          {page.titleShow == 'Y' && (
                            <h5 dangerouslySetInnerHTML={{ __html: page.title }}></h5>
                          )}
                          {page.descriptionShow == 'Y' && (
                            <div style={{ marginTop: "10px" }}>
                              <div dangerouslySetInnerHTML={{ __html: page.description }}></div>
                            </div>
                          )}
                        </div>
                      )}
                      {page.questions && page.questions.map((question, j) => {
                        return (
                          <div key={j} className="form-group">
                            <div className="survey_question">
                              {question.question}{question.required == "Y" && (<span style={{ color: "red" }}>*</span>)}
                            </div>
                            <div className="survey_choice">
                              {question.questionType == "SUBJECT" ? (
                                <TextareaAutosize
                                  aria-label="empty textarea"
                                  value={question.opts[0]?.userValue || ''}
                                  style={{ width: "100%", borderLeft: "1px solid #111", resize: "both" }}
                                  minRows={4}
                                  maxRows={6}
                                  onChange={(e) => onSetOpt(i, j, 0, e.target.value, true)}
                                />
                              ) : (
                                <>
                                  {question.answerType == 'SINGLE' && (
                                    <FormControl sx={{ marginLeft: "0", "& .MuiFormControlLabel-root .MuiFormControlLabel-label": { wordBreak: "break-all" } }}>
                                      <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="radio-buttons-group"
                                      >
                                        {question.opts && question.opts.map((option, k) => {
                                          return (
                                            <FormControlLabel
                                              key={k}
                                              value={option.value}
                                              sx={{ marginLeft: "0", marginBottom: "20px", alignItems: "flex-start" }}
                                              control={<BpRadio onChange={(e) => onSetOpt(i, j, k, e.target.value, e.target.checked)} checked={option.value == option.userValue} />}
                                              label={option.label}
                                            />
                                          )
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  )}
                                  {question.answerType == 'MULTI' && (
                                    <FormGroup>
                                      {question.opts && question.opts.map((option, k) => {
                                        return (
                                          <FormControlLabel
                                            key={k}
                                            sx={{ marginLeft: "0", marginBottom: "20px", alignItems: "flex-start" }}
                                            control={<BpCheckbox onChange={(e) => onSetOpt(i, j, k, e.target.value, e.target.checked)} checked={option.value == option.userValue} />}
                                            value={option.value}
                                            label={option.label}
                                          />
                                        )
                                      }
                                      )}
                                    </FormGroup>
                                  )}
                                </>
                              )}
                            </div>
                            {question.errorMsg != false && question.errorMsg != null && question.errorMsg != "" &&
                              <div className="errorMsg"><img src={errorMsgImg} /><span>{question.errorMsg}</span></div>
                            }
                          </div>
                        )
                      })}
                      {pageTotalCount == pageNum &&
                        <div className="modal_btm_desc" style={{ padding: '20px 30px' }}>
                          <div dangerouslySetInnerHTML={{ __html: surveys?.bottomDescription }}></div>
                        </div>
                      }
                    </div>
                  )
                )
              })}
            </>
          )}
        </div>
        <div className="modal_footer">
          {!submit && pageNum > 1 && (<Button onClick={(e) => prevSurvey(e)}>Prev</Button>)}
          
          {submit ? (<Button style={{ marginLeft: "auto" }} onClick={handleClose}>Confirm</Button>)
          : pageTotalCount > pageNum ?
            (<Button style={{ marginLeft: "auto" }} onClick={(e) => nextSurvey(e, pageNum)}>Next</Button>)
          : (<Button style={{ marginLeft: "auto" }} onClick={(e) => saveSurvey(e, pageNum)}>Submit</Button>)
          }
        </div>
      </Modal>
    </Fragment>
  );
}

export default SurveyModal;
