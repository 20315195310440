import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import { AppContext } from "../../../../components/AppProvider";
import { _API, _U } from "../../../../modules/utils";

import swal from "sweetalert";
import styled from "styled-components";
import DownloadLink from "react-download-link";
import "perfect-scrollbar/css/perfect-scrollbar.css";

export default (props) => {
  const { iam } = useContext(AppContext);
  const { consoleCfg } = props;
  const [fileList, setFileList] = useState([]);

    const fileDownloadCntSave = (item) => {
    if (!item?.idx) {
      swal("파일이 존재하지 않습니다.");
      return;
    }

    const req = {
      path: "/v1/event/session/files/downloadcnt",
      data: {
        idx: item.idx,
        preregIdx: iam.preregIdx,
        passCode: iam?.passCode,
      },
    };

    _API.post(req).then((res) => {
      let { data } = res;
      console.log(`다운로드 성공 : ` + data);
    });
  };

  const loadList = (page) => {
    const req = {
      path: "/v1/event/session/files/list",
      data: {
        status: "Y",
        eventIdx: consoleCfg.eventIdx,
        sessionIdx: consoleCfg.sessionIdx,
      },
    };
    _API.post(req).then((res) => {
      const { data } = res.data;
      setFileList(data);
    });
  };

  const getDataFromURL = (item) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(item?.signedUrl)
          .then((response) => response)
          .then((data) => {
            fileDownloadCntSave(item);
            resolve(data.blob());
          });
      });
    }, 2000);
  /*
    const onSend = (isDelete) => {
        
    };
*/
  const onReceivedMessage = (e) => {
    _U.parseMessage(e)
      .then((data) => {
        switch (data.code) {
          case "SOCKET-RECEIVED":
            return data.data ? JSON.parse(data.data) : null;
        }
      })
      .then((data) => {
        if (data?.mode === "CHAT") {
          if (data.message === "upload_file_alert") loadList();
        }
      });
  };

  useEffect(() => {
    window.addEventListener("message", onReceivedMessage);
    return () => {
      window.removeEventListener("message", onReceivedMessage);
    };
  }, []);

  useEffect(() => {
    loadList();
  }, [consoleCfg]);

  return (
    <Fragment>
      <div className="detail_file_inner_box">
        <h3>파일</h3>
        <ul>
          {fileList?.map((item, i) => (
            <li className="detail_free_content" key={i}>
              <h4>
                <img
                  src="https://cdn.onoffevent.com/detail/icon-file-s.svg"
                  alt="파일"
                />
                <DownloadLink
                  label={
                    item?.customFileName
                      ? item?.customFileName + "." + item.name.split(".")[1]
                      : item.name
                  }
                  filename={
                    item?.customFileName
                      ? item?.customFileName + "." + item.name.split(".")[1]
                      : item.name
                  }
                  exportFile={() => Promise.resolve(getDataFromURL(item))}
                />
              </h4>
              <p>{item?.fileComment}</p>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

const CMessage = styled.div`
  margin-bottom: 0.5rem;
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;
