import axios from "axios";
import swal from "sweetalert";
import { v4 as uuid } from "uuid";
import path from "path";
import moment from "moment";
import "aws-sdk/dist/aws-sdk";
const AWS = window.AWS;

import { _CFG } from "../config";
import _CK from "./cookie";


const api = {
  apiKey: _CFG.apiKey,
  apiHost: _CFG.apiHost(),
};

api.get = (request) => {
  request.method = "GET";
  return api.send(request);
};
api.post = (request) => {
  request.method = "POST";
  return api.send(request);
};
api.put = (request) => {
  request.method = "PUT";
  return api.send(request);
};
api.delete = (request) => {
  request.method = "DELETE";
  return api.send(request);
};

api.send = (request) => {
  const { path, data } = request;
  let { auth, method, retry, isNoErrorNotify } = request;
  if (!auth) auth = _CK.isLogin() ? _CK.getAuth() : "";
  if (!method) method = "GET";
  if (retry === null || retry === undefined) retry = 5;
  if (!isNoErrorNotify) isNoErrorNotify = false;

  return new Promise((resolve, reject) => {
    const req = {
      method,
      url: `${api.apiHost}${path}`,
      headers: {
        apikey: api.apiKey,
        authorization: auth,
      },
      data: method.toUpperCase() === "POST" ? data : null,
      params: method.toUpperCase() === "GET" ? data : null,
    };
    axios(req)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }

        const { data } = res;
        if (data.error !== 0) {
          throw data;
        }

        resolve && resolve(data);
      })
      .catch((e) => {
        if (e.message === "Network Error" && retry > 0) {
          request.retry = retry - 1;
          setTimeout(() => {
            api.send(request);
          }, 500);
          return;
        }

        if (!isNoErrorNotify) {
          api.error(e, reject);
        }
      });
  });
};

api.iam = (auth) => {
  const req = {
    path: "/v1/client/iam",
    method: "POST",
    auth: auth ? auth : _CK.getAuth(),
    data:{
      logKey:_CK.getLogKey(),
      consoleCountView:_CK.getConsoleCountView()
    }
  };
  return api.send(req);
};

api.prereg = (preregIdx, auth) => {
  const req = {
    path: "/v1/client/prereg/info",
    method: "POST",
    auth: auth ? auth : _CK.getAuth(),
    data: { preregIdx: preregIdx },
  };
  return api.send(req);
};

api.loadUploadKeys = () => {
  const req = {
    path: "/v1/upload",
    method: "GET",
  };
  return api.send(req);
};

api.error = (e, reject) => {
  let message = e.message;

  if (e.error) {
    switch (e.error) {
      case -2:
        message = "API Key가 누락되었습니다.";
        break;
      case -3:
        message = "API Key를 찾을 수 없습니다.";
        break;
      case -4:
        message = "인증정보가 누락되었습니다.";
        break;
      case -5:
        message = "인증정보를 확인 할 수 없습니다.";
        break;
      case -12:
        message = "아이디를 입력해 주세요.";
        break;
      case -13:
        message = "비밀번호를 입력해 주세요.";
        break;
      case -14:
        message = "참가 신청 하지 않은 이벤트입니다.";
        break;
      case -15:
        message = "회원상태가 올바르지 않습니다. 관리자와 상담해 주세요.";
        break;
    }
  }

  if(e.error == -99){
    let el = document.createElement('div');
    el.innerHTML = message;
    swal({ title: "Notice", content: el }).then(() => {
      reject && reject(e);
    });
  }else{

    if(message == 'Network Error') {
      return false;
    }
    
    swal({ title: "Notice", text: message }).then(() => {
      reject && reject(e);
    });
  }

  
};

api.upload = ({ aws, file, security, path: location, type }) => {
  return new Promise((resolve, reject) => {
    if (!aws) {
      reject(new Error("AWS 정보를 확인해 주세요."));
      return;
    }
    if (!file) {
      reject(new Error("파일을 입력해 주세요."));
      return;
    }
    const isLocal = _CFG.isLocal();

    const s3 = new AWS.S3({
      accessKeyId: aws.accessKey,
      secretAccessKey: aws.secretKey,
      region: aws.region,
    });

    const uniqueId = uuid().replace(/-/gi, "").toLowerCase();
    const uploadPath = `${isLocal ? "TEMP/" : ""}${
      location ? location : moment().format("YYYY/MM/DD")
    }/${uniqueId}${path.extname(file.name)}`;
    s3.upload(
      {
        Bucket: security ? aws.private.bucket : aws.public.bucket,
        Key: uploadPath,
        Body: file,
        ContentType: file.type,
        ACL: "private",
      },
      async (err, data) => {
        if (err) {
          reject(err);
          return;
        }

        const { Bucket, Key } = data;
        const uploaded = {
          bucket: Bucket,
          path: Key,
          name: file.name,
          size: file.size,
          type: file.type,
        };
        if (type) {
          uploaded.targetType = type;
        }
        const req = { method: "POST", path: "/v1/upload", data: uploaded };
        const res = await api.send(req);
        resolve(res);
      }
    );
  });
};

export default api;
